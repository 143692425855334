import {React} from "react";
import Project from './Project';
import myData from './projects.json';

const ProjectList = () => {


    return (
        <div>
                {myData.map((project, index) => (<Project key={index} project={project} index={index} />))}
        </div>
        );
    }
    
    export default ProjectList;