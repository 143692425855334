import './style/style.css';
import ProjectList from './components/ProjectList'

function App() {
  return (
    <div className="App">
    
      <header></header>

      <div className="main flex">
              <div className="column left">
              <h1>Portfolio</h1>
                <div className="pic text-center">
                    

                    
                </div>
                <div className="intro flex">
                  <p>I have been a professional programmer for over 15 years, working in the Insurance, Video Games and the Healthcare industries.</p>
                  <p>I've been involved in small and large scale projects and am knowledgeable in several technologies, including C#, C++, JavaScript, ReactJS, AngularJS, CSS and T-SQL.</p>
                
                  <a href="./files/David Wright CV 2024.pdf" className="cv_link">Latest CV</a>
                </div>
              </div>
              <div className="column right">
                <div className="top text-center ">
                  <h1>Projects</h1>
                </div>
                <ProjectList />
              </div>
              
          </div>

    </div>
  );
}

export default App;
