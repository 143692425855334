import {React} from "react";

const Project = ({project, index}) => {


    return (
        <div className={(index % 2) ? 'project one' : 'project two'}>
            <a href={"../img/" + project.Image}><img src={"../img/" + project.Image} /></a>
            <div className="details">
                <p><strong className="name">Project Name:</strong>{project.Name}</p>
                <a className="link" href={project.URL}>{project.URL}</a>
                <br />
                { project.Respository !== "" &&
                <a className="link" href={project.Respository}>Repository Link</a>
                }

                {project.Description.map((Description) => (<p>{Description}</p>))}
                
            </div>
        </div>
        );
    }
    
    export default Project;